@tailwind base;
@tailwind components;
@tailwind utilities;

/* font  */

.oswald-regular {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.oswald-semibold {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.oswald-bold {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: bold;
}

body * {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.normal-font {
  font-family: monospace;
}